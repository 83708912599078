@use '@ctrl/ngx-emoji-mart/picker';
@use 'src/assets/scss/patterns';
@use 'src/assets/scss/sf-pro-display';

html {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  font-family: 'SF Pro Display', Arial, Helvetica, sans-serif !important;
  background-color: var(--theme-background) !important;
  width: 100vw;
  overflow-x: hidden;

  // global letter spacing for new font family
  letter-spacing: 0.3px !important;
}

/* Modal animation and styles start */
.modal-dialog {
  @media (max-width: 650px) {
    height: 100vh;
    margin: auto 0;
  }
}

.modal-open {
  // remove scroll from body when modal open
  overflow-y: hidden;
}

// preview modal styles start
.modal-content:has(.media-preview-modal-container) {
  background-color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  border: none !important;
}

// preview modal styles end

.modal-content {
  border-radius: 16px;
  background: var(--block-background) !important;
  border: 1px solid var(--block-border) !important;

  @media (max-width: 650px) {
    position: absolute;
    border-radius: 16px 16px 0 0;
    bottom: 0 !important;
    left: 0;
    right: 0;
    overflow-y: auto;
    max-height: 100vh;
    background: white;
    padding-bottom: 40px;

    animation-name: modal-slide-in;
    animation-duration: 0.7s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
}

.modal-backdrop.show {
  background: var(--milky-background);
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

@media (max-width: 650px) {
  /* Bottom Animation keyframes */
  @keyframes modal-slide-in {
    0% {
      bottom: -100%; /* Start off-screen */
    }
    100% {
      bottom: 0; /* Stop at position 0 */
    }
  }
}

/* Modal animation and styles end */

/* Labels start */
.pointer-cursor {
  cursor: pointer;
}

.selected-content {
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0);
  border: solid 2px transparent;
  background: #eaeaea;
  height: 60px;
  padding: 15px;
  display: grid;
  grid-gap: 4px;
  place-content: center;
  transition: 0.3s ease-in-out all;
}

.selected-content h4 {
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
  color: var(--theme-text-color);
  margin-bottom: 0;
  line-height: 1.4;

  @media (max-width: 499px) {
    font-size: 14px;
  }
}

.selected-label:hover {
  cursor: pointer;
}

.selected-label {
  position: relative;
  width: 100%;
}

.selected-label input {
  display: none;
}

.selected-label .icon {
  width: 20px;
  height: 20px;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  left: 9px;
  transition: 0.3s ease-in-out all;
  transform: scale(1);
  z-index: 1;
}

.selected-label .icon:before {
  content: '\f00c';
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: 'Font Awesome 5 Free', serif;
  font-weight: 900;
  font-size: 12px;
  color: var(--black-100);
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-in-out all;
  transform: scale(2);
}

.selected-label input:checked + .icon {
  background: var(--black-100);
  color: var(--white-100);
  border-color: var(--black-100);
  transform: scale(1.2);
}

.selected-label input:checked + .icon:before {
  color: var(--white-100);
  opacity: 1;
  transform: scale(0.8);
}

.selected-label input:checked ~ .selected-content {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5);
  background: var(--black-100);
  color: var(--white-100);
  border-color: var(--black-100);

  h4 {
    color: #fff;
  }
}

.content-heading,
.block-title {
  color: var(--black-100) !important;
  font-weight: bolder;
}

/* Labels end */

/* Forms styles start */
.form-control,
.input-group-text {
  color: var(--input-color) !important;
  background-color: var(--input-background) !important;
  border-color: var(--input-border-color) !important;
}

.form-control:disabled {
  background-color: var(--disabled-form-input) !important;
}

.amount-numb-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

/* Forms styles end */

/* Text start */
.theme-color {
  color: var(--theme-text-color);
}

.theme-secondary-color {
  color: var(--theme-secondary-text-color);
}

.text-primary {
  color: var(--purple-500) !important;
}

.text-purple {
  color: var(--purple-500) !important;
}

.title-s20-w700 {
  font-size: 20px;
  font-weight: 700;
}

.text-12 {
  font-size: 12px !important;
}

.text-16-mobile {
  @media (max-width: 499px) {
    font-size: 16px;
  }
}

.text-10 {
  font-size: 10px;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.section-title,
.modal-title {
  font-size: 18px;
  //font-weight: 700;
  color: var(--section-title-color);
}

.modal-body > p {
  color: var(--section-title-color);
}

// Title for mobile header
.page-title {
  color: var(--theme-text-color) !important;
  border-bottom: none !important;
  padding-top: 0 !important;
  font-size: 22px;
  font-weight: 800 !important;
  max-width: 315px;

  @media (max-width: 430px) {
    max-width: 265px;
  }
  @media (max-width: 390px) {
    max-width: 220px;
  }
  @media (max-width: 350px) {
    max-width: 190px;
  }
}

// gradients for mobile iOS & android
.iphone {
  @media (max-width: 650px) {
    .pension-planing-container {
      position: relative;
      top: -128px !important;

      .advisor-dashboard-block-container {
        padding-top: 90px !important;
      }
    }
  }
}

.android {
  @media (max-width: 650px) {
    .pension-planing-container {
      position: relative;
      top: -128px !important;

      .advisor-dashboard-block-container {
        padding-top: 90px !important;
      }
    }
  }
}

:root {
  --base-gradient-height: 250px;
}
.contracts-container,
.wealth-container,
.bank-card-manager-container {
  .navigation-position {
    margin-bottom: 15px;

    @media (max-width: 650px) {
      position: absolute;
      top: var(--dynamic-gradient-height);
    }
  }
}

// blurred state for amounts
.blurred-state {
  filter: blur(6px);
}

.placeholder-text {
  color: #999 !important;
}

/* Text end */

/* Buttons styles start */
// Disabled styles
.btn.disabled,
.btn:disabled {
  opacity: 0.45 !important;
}

.btn-outline {
  &:hover {
    color: var(--theme-text-color);
  }
}

// Purple button
.btn-primary {
  background: var(--purple-500) !important;
  border: 1px solid var(--purple-500) !important;
}

.btn-outline-primary {
  color: var(--purple-500) !important;
  border-color: var(--purple-500) !important;

  &:hover {
    background-color: var(--purple-500) !important;
    color: var(--white-100) !important;
  }
}

.btn-purple {
  color: #fff;
  background-color: var(--purple-500) !important;
  border: 1px solid var(--purple-500);
  //border-radius: 16px !important;

  &:hover {
    color: #fff !important;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.175) !important;
  }

  img {
    filter: invert(100%) sepia(0%) saturate(4369%) hue-rotate(55deg) brightness(107%) contrast(116%);
  }
}

.btn-action-secondary {
  align-items: center;
  background: var(--block-border) !important;
  color: var(--purple-500) !important;

  &:hover {
    background: var(--purple-500) !important;
    color: var(--white-100) !important;
  }
}

.btn-action-secondary-active {
  background: var(--purple-500) !important;
  color: var(--white-100) !important;
}

.btn-hover {
  color: var(--theme-text-color);
  background-color: var(--hover-btn-bg) !important;
  border: 1px solid var(--transaction-hover-bg);
  border-radius: 30px !important;

  &:hover {
    background-color: var(--purple-500) !important;
    color: var(--white-100) !important;
  }
}

// orange btn
.btn-orange {
  background: var(--orange-100);
  color: var(--orange-500);

  &:hover {
    background: var(--orange-500);
    color: var(--white-100);
  }
}

// green btn
.btn-green {
  background: var(--green-200);
  color: var(--green-400);

  &:hover {
    background: var(--green-400);
    color: var(--white-100);
  }
}

.purple-outline-btn {
  align-items: center;
  border: 1px solid var(--purple-500);
  color: var(--purple-500);

  .spinner-border {
    color: var(--purple-500);
  }

  &:hover {
    background: var(--purple-500);
    color: var(--white-100);

    .spinner-border {
      color: var(--white-100) !important;
    }
  }
}

// Grey categories filter button
.wealth-category-filter-btn {
  align-items: center;
  border: 1px solid var(--theme-secondary-text-color);
  color: var(--wealth-category-filter-text);

  &.active {
    border: 1px solid var(--cyan-800);
    color: var(--cyan-800);
  }

  &:hover {
    border-color: var(--cyan-800);
    color: var(--cyan-800);
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: var(--wealth-card-bg);
      border-color: var(--cyan-800);
      color: var(--cyan-800);
    }
  }
}

// Button for modals or Popups
.btn-modal {
  padding: 15px 20px;
}

// Green Wealth Button
.btn-wealth {
  background-color: var(--wealth-card-button);
  color: var(--white-100);

  &:hover {
    background-color: var(--wealth-card-button-hover-bg);
    color: var(--wealth-card-button-hover-text);
    border: 1px solid var(--wealth-card-button-hover-text);
  }
}

.btn-white {
  background: var(--white-100);
  color: var(--black-100);
  border: 1px solid var(--white-100);

  &:hover {
    background: var(--purple-500);
    color: var(--white-100);
    border: 1px solid var(--purple-500);
  }
}

.btn-gradient-title {
  background: var(--btn-gradient-title-bg);
  color: var(--btn-gradient-title-color);
  backdrop-filter: blur(2px);

  &:hover {
    background: var(--purple-500);
    color: var(--white-100);
  }
}

.btn-3D-shadow {
  position: relative;
  box-shadow: var(--3d-button-shadow);
  backdrop-filter: blur(40px);
  color: var(--3d-color) !important;
  border: none; /* Remove default borders */
  border-radius: 30px; /* Adds rounded corners */
  z-index: 1; /* Ensure the content is above the pseudo-element */
  overflow: hidden; /* Clip the pseudo-element to match rounded corners */

  /* Add hover effect if needed */
  &:hover {
    color: var(--3d-color) !important;
  }

  /* Pseudo-element for border with radius */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Place the pseudo-element behind */
    border-radius: inherit; /* Match the button's border-radius */
    padding: 2px; /* Border thickness */
    background: var(--3d-button-background) !important;
    -webkit-mask: padding-box;
    mask: padding-box;
    clip-path: inset(0);
  }
}

/* Buttons styles end */

/* Datepicker styles start */
.bs-datepicker {
  padding-top: 8px;

  .bs-datepicker-head {
    background-color: var(--purple-500) !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .bs-datepicker-body {
    border-radius: 0 0 6px 6px;
  }

  .selected {
    background-color: var(--purple-500) !important;
  }
}
/* Datepicker styles end */

/* Image Carousel Global Style starts*/

.carousel {
  width: 100%;
  overflow: hidden;

  .carousel-inner {
    height: 250px;

    .carousel-item {
      aspect-ratio: 4/3;
    }

    .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 250px;
    }
  }
}

.empty-image-slider {
  color: var(--purple-500);
  background-color: var(--image-upload-bg);
  border: 1px dashed var(--gray-500);
  padding: 1px;
  border-radius: 16px;

  .empty-image-slider-img {
    mix-blend-mode: luminosity;
  }
}

/* Image Carousel Global Style ends*/

/* scrolling issue fix for iOS */
@media (max-width: 600px) {
  .min-form-height {
    min-height: 700px;
  }
}

/* Truncate blocks (ellipsis) start */
.parent-truncate {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.child-truncate {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Truncate blocks (ellipsis) end */

/* Block styles start */
.theme-background-block-bg {
  background: var(--block-background);
}

.theme-border {
  border: 1px solid var(--block-border);
}

.inside-block-bg {
  background: var(--inside-block);
}

.block-title {
  color: var(--theme-text-color) !important;
}

.br-16 {
  border-radius: 16px !important;
  box-shadow: 0 8px 30px 2px rgba(0, 0, 0, 0.03) !important;
}

.gray-border {
  border: 1px solid var(--gray-200) !important;
}

/* Block styles end */

/* Bootstrap components styles start */
.css-radio.css-control-secondary .css-control-input:checked ~ .css-control-indicator::after {
  background-color: var(--purple-500) !important;
}

.css-switch .css-control-input ~ .css-control-indicator {
  background-color: var(--switch-toggler) !important;
}

.css-switch.css-control-primary .css-control-input:checked ~ .css-control-indicator {
  background-color: var(--purple-500) !important;
}

.css-switch .css-control-input ~ .css-control-indicator {
  height: 23px !important;
  width: 47px !important;

  &:after {
    left: 4px !important;
    width: 20px !important;
  }
}

.nav-tabs-alt .nav-item.show .nav-link,
.nav-tabs-alt .nav-link.active {
  box-shadow: inset 0 -2px var(--purple-500) !important;
}

.nav-tabs-alt .nav-link:focus,
.nav-tabs-alt .nav-link:hover {
  box-shadow: inset 0 -2px var(--purple-500) !important;
}

.badge {
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  padding: 8px 16px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  color: var(--white-100);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 99999px;
}

.badge.badge-outlined {
  background-color: transparent;
}

.badge.badge-purple {
  border-color: var(--purple-500);
  background: var(--purple-badge-bg);
  color: var(--purple-500);

  &:hover {
    background: var(--purple-500);
    color: var(--white-100);
  }
}

.badge-purple-active {
  border-color: var(--purple-500) !important;
  background: var(--purple-500) !important;
  color: var(--white-100) !important;
}

.badge.badge-outlined.badge-primary {
  border-color: var(--purple-500) !important;
  color: var(--purple-500);

  &:hover {
    background: var(--purple-500);
    color: var(--white-100);
  }
}

.badge-primary-active {
  background: var(--purple-500) !important;
  color: var(--white-100) !important;
}

.badge.badge-outlined.badge-light {
  border-color: var(--white-100) !important;
  color: var(--white-100);

  &:hover {
    background: var(--white-100);
    color: var(--purple-500);
  }
}

.badge-light-active {
  background: #fff !important;
  color: var(--purple-500) !important;
}

// toastr styles
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  @media (max-width: 950px) {
    width: 94% !important;
  }
}

.toast-bottom-right {
  margin-bottom: 30px;
}

.toast-bottom-center {
  margin-bottom: 75px;
}

.toast-info {
  background-image: url('assets/icon/check.png');
  background-color: var(--black-100) !important;
  opacity: 0.85 !important;
  color: var(--white-100) !important;
  border-radius: 12px !important;
}

// multiselect styles (ngx-bootstrap-multiselect
.multi-select-container {
  width: 100% !important;

  .dropdown-menu {
    width: 250px !important;

    @media (max-width: 600px) {
      width: 100% !important;
    }
  }
}

.multi-select-btn {
  background: var(--purple-500) !important;
  color: var(--white-100) !important;
  width: 250px !important;

  @media (max-width: 600px) {
    width: 100% !important;
  }
}

.multi-select-option {
  width: 250px !important;

  @media (max-width: 600px) {
    width: 100% !important;
  }
}

/* Bootstrap components styles end */

/* Add Scroll Bar like MacOS for problems Screen start */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 16px;
  background-color: #babac0;
}

*::-webkit-scrollbar-button {
  display: none;
}

/* Add Scroll Bar like MacOS for problems Screen end */

/* Purple radio button start */
input[id='purpleRadioBtn'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--white-100);
  /* Not removed via appearance */
  margin: 0;
  width: 12px;
  height: 12px;
  border: 1px solid var(--purple-500);
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
  cursor: pointer;
}

input[id='purpleRadioBtn']::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background: var(--purple-500);
}

input[id='purpleRadioBtn']:checked::before {
  transform: scale(1);
}

input[id='purpleRadioBtn']:focus {
  outline: max(2px, 0.15em) solid #8681fc;
  outline-offset: max(2px, 0.15em);
}

/* Purple radio button end */

/* Stepper styles start */
.stepper {
  .icon-container {
    img {
    }
  }

  .selected-content {
    background: var(--finance-check-btn-bg) !important;
    border: 1px solid var(--block-border);
    color: var(--theme-text-color) !important;
    border-radius: 8px;

    @media (max-width: 499px) {
      height: 50px;
    }
  }

  .selected-label input:checked ~ .selected-content {
    background: var(--purple-500) !important;
    border: 1px solid var(--purple-500) !important;
  }

  .selected-label input:checked ~ .selected-content h4 {
    color: var(--white-100);
  }
}

.step-container {
  height: 100%;
  min-height: 400px;

  @media (max-height: 755px) {
    min-height: 450px;
  }

  @media (max-height: 755px) {
    .max-height-limit {
      display: none;
    }

    .max-height-limit-content {
      margin-top: -15px;
    }
  }
}

.middle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  //min-height: 440px;
  //padding-bottom: 100px;
  min-height: 330px;
  padding-bottom: 0 !important;

  //@media (max-height: 755px) {
  //  min-height: 330px;
  //  padding-bottom: 0 !important;
  //}
}

/* Stepper styles end */

/* Gaps start */
.gap-1 {
  gap: 5px !important;
}

.gap-2 {
  gap: 10px !important;
}

.gap-3 {
  gap: 15px !important;
}

.gap-4 {
  gap: 20px !important;
}

.gap-5 {
  gap: 30px !important;
}

/* Gaps end */

/* Date picker styles start */
.bs-datepicker {
  padding-top: 0 !important;
  border-radius: 12px;
}

.bs-datepicker-container {
  padding: 0;
}

.picker-left {
  left: -79px !important;
  top: 10px !important;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  line-height: 0.8;
}

/* Date picker styles end */

/* Drag and Drop area styles start */
.drag-and-drop-container {
  border: 1px dashed var(--purple-400);
  background: var(--drag-zone-bg);
  height: 200px;
}

.disabled-upload-area {
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 100%;
  height: 150px;
  border: 1px dashed var(--purple-400);
  cursor: pointer;
  border-radius: 10px;
  background: var(--drag-zone-bg);
}

.drag-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 100%;
  height: 150px;
  border: 1px dashed var(--purple-400);
  cursor: pointer;
  border-radius: 10px;
  background: var(--drag-zone-bg);
}

.drag-zone-effect {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--drag-zone-bg);
  width: 100%;
  border: 1px dashed var(--purple-400);
  padding: 15px;
  border-radius: 16px;
}

/* Drag and Drop area styles end */

/* Mark styles start */
mark {
  background-color: var(--gray-200);
  padding-left: 0;
  padding-right: 0;
}

/* Mark styles end */

/* Fade in Animation start */
.fade-in-animation-msg-container-forwards {
  opacity: 0;
  animation-name: fade-in-animation;
  animation-duration: 0.6s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-animation-forwards {
  opacity: 0;
  animation-name: fade-in-animation;
  animation-duration: 0.3s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fade in Animation end */

/* Slide up Animation start */
.slide-up-animation {
  transform: translateY(100%);
  opacity: 0; /* Initially hide the message */
  animation-name: slide-up-animation;
  animation-duration: 0.3s;
  //animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes slide-up-animation {
  0% {
    transform: translateY(100%); /* Start below the container */
    opacity: 0; /* Initially hidden */
  }
  50% {
    opacity: 0.5; /* Stay hidden until halfway through the animation */
  }
  100% {
    transform: translateY(0); /* Slide up to its original position */
    opacity: 1; /* Fully visible at the end */
  }
}

/* Slide up Animation end */

/* Wealth Section Global styles */

.preview-current-value {
  background-color: var(--wealth-card-bg);
  border: 1px solid var(--cyan-200);
  color: var(--wealth-category-filter-text);
}

.total-wealth-balance {
  color: var(--wealth-category-filter-text);
}

.invalid-feedback {
  display: block;
  min-height: 16.8px;
}

.mobility-stepper-container,
.real-estate-stepper-container,
.art-stepper-container,
.jewelry-stepper-container,
.precious-metals-stepper-container,
.other-stepper-container {
  .progress {
    margin-bottom: 25px;

    .progress-bar {
      background: var(--purple-500);

      &.bg-success {
        background: var(--green-400);
      }
    }
  }

  .progress-finished {
    background: #10b981 !important;
  }

  .text-finished {
    color: #10b981 !important;
  }
}

/* Wealth Section end */
