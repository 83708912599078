:root {
  --black-100: #000;
  --white-100: #fff;

  --gray-100: #fafafa;
  --gray-200: #e7e6fe;
  --gray-300: #cccccc;
  --gray-500: #808080;

  --dark-400: #b6b6b6;
  --dark-500: #666666;
  --dark-600: #333333;
  --dark-800: #1a1a1a;
  --dark-900: #0d0d0d;

  --purple-100: #f3f2ff;
  --purple-400: #8681fc;
  --purple-500: #6e68fb;
  --purple-900: #6e68fb33;

  --green-200: #d1fae5;
  --green-400: #10b981;
  --green-500: #10b981;
  --green-800: #065f46;
  --green-900: #134e4a;

  --cyan-200: #6ee7b7;
  --cyan-400: #34d399;
  --cyan-600: #0d9488;
  --cyan-800: #18715b;

  --orange-100: #ffedd5;
  --orange-300: #fbbf24;
  --orange-500: #f97316;
  --orange-800: #fb923c33;

  --red-400: #dc2626;

  --blue-100: #f0f9ff;
  --blue-300: #cffafe;
  --blue-500: #0095f8;
  --blue-800: #003a60;
}

/* Light theme styles */
.light-theme {
  --theme-background: var(--gray-100);
  --theme-text-color: var(--black-100);
  --theme-secondary-text-color: var(--gray-500);
  --header-milky-bg: var(--gray-100);
  --header-mobile-bottom-border: var(--dark-900);
  --mobile-bottom-navigation-text: var(--dark-500);
  --mobile-bottom-nav-icon: var(--black-100);
  --side-bar-bg: Var(--white-100);
  --side-bar-border-right: none;
  --side-bar-icon: var(--black-100);
  --user-side-bar-head-section: var(--gray-100);
  --section-title-color: var(--black-100);
  --block-background: var(--white-100);
  --block-border: var(--gray-200);
  --inside-block: var(--white-100);
  --bank-connection-block-bg: var(--gray-200);
  --bank-connection-border: var(--purple-400);
  --carousel-widget-bg: var(--gray-200);
  --stepper-circles-bg: var(--purple-100);
  --finance-check-btn-bg: var(--purple-100);
  --switch-toggler: var(--gray-500);
  --chart-numbers: var(--dark-500);
  --input-color: var(--black-100);
  --input-background: var(--white-100);
  --input-border-color: var(--gray-200);
  --drag-zone-bg: var(--purple-100);
  --purple-badge-bg: var(--white-100);
  --transaction-hover-bg: var(--purple-100);
  --transaction-category-bg: var(--purple-100);
  --transaction-amount-bg: #f2f2f2;
  --transaction-tag-inactive-border-color: var(--gray-300);
  --transaction-tag-active-color: var(--purple-500);
  --transaction-tag-button-foreground-color: var(--gray-300);
  --transaction-tag-collapse-button-foreground-color: var(--gray-300);
  --transaction-tag-collapse-button-background-color: var(--gray-200);
  --transactio-green-amount-bg: var(--green-200);
  --purple-expiration-bg: var(--gray-200);
  --purple-expiration-border: var(--purple-400);
  --purple-expiration-icon: var(--purple-500);
  --purple-expiration-bank: var(--purple-500);
  --orange-expiration-bg: var(--orange-100);
  --orange-expiration-border: var(--orange-300);
  --orange-expiration-icon: var(--orange-300);
  --orange-expiration-bank: var(--orange-300);
  --blue-expiration-bg: var(--blue-100);
  --blue-expiration-border: var(--blue-300);
  --blue-expiration-icon: var(--blue-500);
  --blue-expiration-bank: var(--blue-500);
  --green-rating-bg: var(--green-200);
  --green-ration-text: var(--green-400);
  --advisor-cloud-msg: var(--purple-100);
  --system-cloud-msg: var(--gray-300);
  --vinhub-option-border: var(--white-100);
  --vinhub-advisor-cloud: var(--white-100);
  --vinhub-client-cloud: var(--purple-100);
  --attachment-block-bg: var(--purple-100);
  --attachment-block-border: var(--purple-500);
  --attachment-text: var(--purple-500);
  --contract-icon-bg: var(--white-100);
  --milky-background: var(--gray-300);
  --disabled-form-input: var(--gray-100);
  --progress-bar-bg: var(--purple-100);
  --surplus-income: #fcd34d;
  --surplus-outcome: var(--purple-500);
  --hover-btn-bg: var(--white-100);
  --detected-contract-card-bg: var(--purple-100);
  --navigation-tabs-bg: var(--gray-300);
  --navigation-tabs-slider: var(--white-100);
  --btn-gradient-title-bg: var(--white-100);
  --btn-gradient-title-color: var(--black-100);

  --wealth-card-bg: var(--green-200);
  --wealth-card-bg-border: var(--cyan-200);
  --wealth-card-button: var(--cyan-600);
  --wealth-card-total-asset-text: var(--gray-500);
  --wealth-card-button-hover-bg: var(--green-200);
  --wealth-card-button-hover-text: var(--wealth-card-button);
  --wealth-category-filter-text: var(--dark-500);

  --image-upload-bg: var(--purple-100);
  --3d-button-shadow: 0px 3px 20.4px 0px rgba(0, 0, 0, 0.12);
  --3d-button-background: var(--white-100);
  --3d-color: var(--black-100);

  // gradient backgrounds
  --purple-gradient: linear-gradient(0deg, rgba(91, 60, 143, 0) 2%, #aa78ff 40%, #5b3c8f 100%), #fafafa;
  --orange-gradient: linear-gradient(
      0deg,
      rgba(208, 119, 30, 0) 2%,
      rgb(255 128 0 / 60%) 40%,
      rgba(208, 119, 30, 0.8) 100%
    ),
    #fafafa;
  --brown-gradient: linear-gradient(
      0deg,
      rgba(208, 140, 30, 0) 2%,
      rgb(255 158 0 / 60%) 40%,
      rgba(208, 140, 30, 0.8) 100%
    ),
    #fafafa;
  --green-gradient: linear-gradient(
      0deg,
      rgba(122, 189, 35, 0) 2%,
      rgb(132 233 2 / 60%) 40%,
      rgba(122, 189, 35, 0.8) 100%
    ),
    #fafafa;
  --sea-gradient: linear-gradient(
      0deg,
      rgba(27, 136, 172, 0) 2%,
      rgb(1 160 213 / 60%) 40%,
      rgba(27, 136, 172, 0.8) 100%
    ),
    #fafafa;
  --blue-gradient: linear-gradient(
      0deg,
      rgba(27, 136, 172, 0) 2%,
      rgb(0 164 218 / 60%) 40%,
      rgba(27, 136, 172, 0.8) 100%
    ),
    #fafafa;
  --deep-blue-gradient: linear-gradient(
      0deg,
      rgba(37, 106, 246, 0) 2%,
      rgb(90 144 255 / 60%) 40%,
      rgba(37, 106, 246, 0.8) 100%
    ),
    #fafafa;
  --light-blue-gradient: linear-gradient(
      0deg,
      rgba(37, 169, 246, 0) 2%,
      rgb(0 161 255 / 60%) 40%,
      rgba(37, 169, 246, 0.96) 100%
    ),
    #fafafa;
  --earth-gradient: linear-gradient(
      0deg,
      rgba(28, 178, 141, 0) 2%,
      rgb(0 221 167 / 60%) 40%,
      rgba(28, 178, 141, 0.8) 100%
    ),
    #fafafa;
}

/* Dark theme styles */
.dark-theme {
  --theme-background: var(--black-100);
  --theme-text-color: var(--white-100);
  --theme-secondary-text-color: var(--gray-300);
  --header-milky-bg: var(--dark-900);
  --header-mobile-bottom-border: var(--dark-600);
  --mobile-bottom-navigation-text: var(--dark-400);
  --mobile-bottom-nav-icon: invert(100%) sepia(0%) saturate(4369%) hue-rotate(90deg) brightness(133%) contrast(161%) !important;
  --side-bar-bg: Var(--dark-900);
  --side-bar-border-right: var(--dark-600);
  --side-bar-icon: invert(100%) sepia(0%) saturate(4369%) hue-rotate(55deg) brightness(107%) contrast(116%) !important;
  --user-side-bar-head-section: var(--dark-900);
  --section-title-color: var(--white-100);
  --block-background: var(--dark-800);
  --block-border: var(--dark-600);
  --inside-block: var(--black-100);
  --bank-connection-block-bg: var(--dark-800);
  --bank-connection-border: var(--dark-500);
  --carousel-widget-bg: var(--dark-800);
  --stepper-circles-bg: var(--black-100);
  --finance-check-btn-bg: var(--black-100);
  --switch-toggler: var(--black-100);
  --chart-numbers: var(--white-100);
  --input-color: var(--white-100);
  --input-background: var(--black-100);
  --input-border-color: var(--dark-600);
  --drag-zone-bg: var(--black-100);
  --purple-badge-bg: var(--black-100);

  --transaction-hover-bg: var(--dark-600);
  --transaction-category-bg: var(--dark-600);
  --transaction-amount-bg: var(--dark-900);
  --transaction-tag-inactive-border-color: var(--gray-300);
  --transaction-tag-button-foreground-color: var(--gray-300);
  --transaction-tag-active-color: var(--purple-500);
  --transaction-tag-collapse-button-foreground-color: white;
  --transaction-tag-collapse-button-background-color: var(--purple-500);
  --transactio-green-amount-bg: var(--green-800);

  --purple-expiration-bg: var(--purple-900);
  --purple-expiration-border: var(--purple-400);
  --purple-expiration-icon: var(--white-100);
  --purple-expiration-bank: var(--purple-500);
  --orange-expiration-bg: var(--orange-800);
  --orange-expiration-border: var(--orange-300);
  --orange-expiration-icon: var(--white-100);
  --orange-expiration-bank: var(--orange-300);
  --blue-expiration-bg: var(--blue-800);
  --blue-expiration-border: var(--blue-500);
  --blue-expiration-icon: var(--white-100);
  --blue-expiration-bank: var(--blue-500);
  --green-rating-bg: var(--green-800);
  --green-ration-text: var(--green-200);
  --advisor-cloud-msg: var(--black-100);
  --system-cloud-msg: var(--gray-500);
  --vinhub-option-border: var(--dark-600);
  --vinhub-advisor-cloud: var(--dark-600);
  --vinhub-client-cloud: var(--purple-500);
  --attachment-block-bg: var(--dark-800);
  --attachment-block-border: var(--white-100);
  --attachment-text: var(--white-100);
  --contract-icon-bg: var(--gray-300);
  --milky-background: var(--dark-900);
  --disabled-form-input: var(--dark-600);
  --progress-bar-bg: var(--black-100);
  --surplus-income: #9b8217;
  --surplus-outcome: #4642a6;
  --hover-btn-bg: var(--dark-600);
  --detected-contract-card-bg: var(--black-100);
  --navigation-tabs-bg: var(--dark-600);
  --navigation-tabs-slider: var(--black-100);
  --btn-gradient-title-bg: var(--black-100);
  --btn-gradient-title-color: var(--white-100);

  /* ... wealth dark theme styles ... */
  --wealth-card-bg: var(--cyan-600);
  --wealth-card-bg-border: var(--green-900);
  --wealth-card-button: var(--green-900);
  --wealth-card-total-asset-text: var(--white-100);
  --wealth-card-button-hover-bg: var(--green-800);
  --wealth-card-button-hover-text: var(--gray-200);
  --wealth-category-filter-text: var(--white-100);

  --image-upload-bg: var(--dark-800);
  --3d-button-shadow: 0 3px 20.4px rgba(0, 0, 0, 0.25);
  --3d-button-background: radial-gradient(
    rgb(0 0 0),
    rgb(0 0 0 / 18%) 13.35%,
    rgb(0 0 0 / 0%) 49.51%,
    rgb(171 171 171 / 11%) 84.73%,
    rgba(255, 255, 255, 0.53) 100%
  );
  --3d-color: var(--white-100);

  // gradient backgrounds
  --purple-gradient: linear-gradient(
      0deg,
      rgba(91, 60, 143, 0) 2%,
      rgb(69 44 112 / 60%) 40%,
      rgba(91, 60, 143, 0.8) 100%
    ),
    #000;
  --orange-gradient: linear-gradient(
      0deg,
      rgba(208, 119, 30, 0) 2%,
      rgb(152 87 21 / 60%) 40%,
      rgba(208, 119, 30, 0.8) 100%
    ),
    #000;
  --brown-gradient: linear-gradient(
      0deg,
      rgba(208, 140, 30, 0) 2%,
      rgb(164 101 0 / 60%) 40%,
      rgba(208, 140, 30, 0.8) 100%
    ),
    #000;
  --green-gradient: linear-gradient(
      0deg,
      rgba(122, 189, 35, 0) 2%,
      rgb(74 132 0 / 60%) 40%,
      rgba(122, 189, 35, 0.8) 100%
    ),
    #000;
  --sea-gradient: linear-gradient(
      0deg,
      rgba(27, 136, 172, 0) 2%,
      rgb(0 100 133 / 60%) 40%,
      rgba(27, 136, 172, 0.8) 100%
    ),
    #000;
  --blue-gradient: linear-gradient(
      0deg,
      rgba(27, 136, 172, 0) 2%,
      rgb(0 93 124 / 60%) 40%,
      rgba(27, 136, 172, 0.8) 100%
    ),
    #000;
  --deep-blue-gradient: linear-gradient(
      0deg,
      rgba(37, 106, 246, 0) 2%,
      rgb(27 82 195 / 60%) 40%,
      rgba(37, 106, 246, 0.8) 100%
    ),
    #000;
  --light-blue-gradient: linear-gradient(
      0deg,
      rgba(37, 169, 246, 0) 2%,
      rgb(27 128 186 / 60%) 40%,
      rgba(37, 169, 246, 0.96) 100%
    ),
    #000;
  --earth-gradient: linear-gradient(
      0deg,
      rgba(28, 178, 141, 0) 2%,
      rgb(44 141 117 / 60%) 40%,
      rgba(28, 178, 141, 0.8) 100%
    ),
    #000;

  /* Date picker dark theme */
  --asset-badge-border-color: #35353a;
  --asset-badge-text-color: #e6e6e6;
  --asset-badge-subtitle-color: #b3b3b3;
  --asset-preview-title-color: var(--asset-badge-text-color);

  .bs-datepicker .bs-datepicker-body {
    background-color: black !important;
    border: 1px solid var(--block-border) !important;
  }

  .skeleton-loader {
    background: rgb(61 61 61) no-repeat !important;
  }
}
